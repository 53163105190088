// 职责: 单页跳转首次预取列表页html

import { generateRouterParams, defaultRequestInfo, setCurrentToRoute, getPdeParams, PointerOverGoodsRequest } from './utils.js'
import schttp from 'public/src/services/schttp'
import generateParams from './generateParams.js'
import { PRODUCT_LIST_API_URL_MAP } from './constant.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import { pointerOverGoodsRequest, changePreHtmlUpdate } from './index.js'
import { markPoint } from 'public/src/services/mark/index.js'

let abortionInstance = null

// 骨架屏元素
let skeletonEl = null

const removeSkeleton = () => {
  skeletonEl?.remove?.()
  skeletonEl = null
}

function isOpenPrerenderGoodsApp() {
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return !isIos // ios不预取html
}

/**
 * @description 预取列表页html
 * @param {string} url // 跳转的url
 * @returns {boolean} // 是否列表预渲染html
*/
function invokeRequestGoodsPreloadHtml(url) {
  window._products_promise_preload_html = null

  if (window._products_init_container) return false // 已经初始化过了
  if (!isOpenPrerenderGoodsApp()) return false

  if (gbCommonInfo.isDebug) {
    console.log('%c 列表单页水合 start ', 'color: #00c663;font-size: 16px; background: #000; padding: 2px 4px;')
  }

  const toRouteParams = generateRouterParams(url)
  if (!toRouteParams) return false // 不是跳转列表页的路由,

  abortionInstance?.abort?.()
  setCurrentToRoute(toRouteParams)
  const params = generateParams(defaultRequestInfo(toRouteParams.query))
  params.preloadHtmlUrl = url // 预取html的url，也是标识
  abortionInstance = new SchttpAbortCon()

  changePreHtmlUpdate(true) // 开启预取html

  window._products_promise_preload_html = schttp({
    url: PRODUCT_LIST_API_URL_MAP[toRouteParams.name],
    params,
    headers: getPdeParams(),
    signal: abortionInstance.signal,
  }).then(res => {
    return new Promise((resolve) => {
      const { contextForSSR, preloadImgs } = res || {}
    
      if (!contextForSSR) { // 走客户端渲染
        removeSkeleton()
        resolve()
        return
      }

      window._products_ssr_data = contextForSSR
      handlePreloadFetchData(res)
      if (preloadImgs?.length) {  // 预加载图片，提前上屏有内容。
        const MARK_NAME = 'ImgPreloadSpaHydration' // 采集图片预加载的时间
        markPoint({ eventName: MARK_NAME, tag: 'begin' } )
        preloadImg([...new Set(preloadImgs)]).then(() => {
          markPoint({ eventName: MARK_NAME, tag: 'end' } )
          requestAnimationFrame(() => resolve())
        })
      } else {
        requestAnimationFrame(() => resolve())
      }
      removeSkeleton()
    })
  }).catch((e) => {
    removeSkeleton()
    window._products_ssr_data = null
    console.warn(e, 'error in preload html')
  }).finally(() => { 
    changePreHtmlUpdate(false)  
  })
}

function handlePreloadFetchData(result) {
  const SSR_ATTR = 'data-server-rendered'
  const { appendedSelector, ssrHTML, ssrSuiCSS, ssrVueCSS } = result || {}

  if (ssrSuiCSS && appendedSelector) {
    document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
    window.gbSuiSsrData = [... new Set((window.gbSuiSsrData || []).concat(appendedSelector))]
  }

  if (ssrVueCSS) {
    document.querySelector('#prerender-css-products').innerHTML = ssrVueCSS
  }

  const ssrHTMLFragment = document.createRange().createContextualFragment(ssrHTML)
  const ssrHTMLFragmentEl = ssrHTMLFragment.querySelector('#product-list-v2')
  if (ssrHTMLFragmentEl) {
    document.querySelector('#prerender-app').appendChild(ssrHTMLFragmentEl)
    window._products_prerender_el = document.querySelector('#prerender-app #product-list-v2')
    window._products_prerender_el.setAttribute(SSR_ATTR, true) // 标记为ssr渲染的dom
  }
}

const preloadGoodsRequestControl = new PointerOverGoodsRequest({
  fetch: invokeRequestGoodsPreloadHtml,
  cancelToken: () => abortionInstance?.abort?.(),
})

const preloadGoodsRequest = {
  triggerNotice(path) {
    preloadGoodsRequestControl.triggerNotice(path)
    pointerOverGoodsRequest.triggerNotice(path)
  },
  click(path) {
    const isPreloadFetch = preloadGoodsRequestControl.requestUrl

    preloadGoodsRequestControl.click()
    pointerOverGoodsRequest.click()

    // 判断是否是预取html的url
    if (window._products_promise_preload_html && !window._products_init_container && isPreloadFetch) {
      window._gb_app_.$store.state.rootStatus.exchangingSpaScene = true // 隐藏页面的app, 等真实页面渲染完成后再设置false
      skeletonEl = document.querySelector('#prerender-products-skeleton')
      if (skeletonEl) {
        skeletonEl.style.display = 'block' // 进行开启骨架屏
      }
      // header头部可能会在吸顶状态, 进行复位
      window.forceUpdateHeaderFixed?.({ forceFixed: false, routeName: 'product-list-v2' })
      requestIdleCallback(() => window.JOURNEY_BRANCH?.updateSync?.(path))
    }
  }
}

export {
  preloadGoodsRequest
}
